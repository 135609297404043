.deployment-container {
    width: 100%;
    text-align: left;
    max-width: 325px;
}

.deployment-type {
    width: 100%;
}

.dropdown-menu {
    border: 1px solid lightgray;
    border-radius: 4px;
    font-family: "Source Sans Pro", monospace;
    font-size: 1rem;
    color: rgb(47, 47, 47);
    outline-color: var(--sg-blurple);
    padding: 5px;
    height: 38px;
    width: 100%;
    max-width: 100%;
    min-width: fit-content;
}

.ns-check.dark {
    background-color: var(--sg-bg-dark-3);
    border: 1px solid var(--sg-dark-gray);
    appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 2px;
}

.ns-check.dark:checked:after {
    content: '\2713';
    font-size: 14px;
    color: white;
    position: relative;
    top: -2.5px;
}

.dropdown-menu.dark {
    background-color: var(--sg-bg-dark-2);
    border: 1px solid var(--sg-dark-gray);
    outline-color: var(--sg-dark-gray);
    color: white;
}

.text-input {
    border: 1px solid lightgray;
    border-radius: 4px;
    font-family: "Source Code Pro", monospace;
    font-size: 1rem;
    color: rgb(47, 47, 47);
    outline-color: var(--sg-blurple);
    padding-left: 8px;
    margin-top: 3px;
    height: 32px;
    width: 97%;
}

.text-input.dark {
    background-color: var(--sg-bg-dark-2);
    border: 1px solid var(--sg-dark-gray);
    outline-color: var(--sg-dark-gray);
    color: white;
}

.namespace-check {
    margin-top: 10px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
    font-size: .8rem;
}

.namespace-label {
    margin-left: 4px;
    min-width: fit-content;
}

.namespace-label.dark {
    color: white;
}

.namespace-input {
    margin-top: 10px;
    width: 100%;
}
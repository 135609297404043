:root {
  --sg-blurple: #5033e1;
  --sg-light-gray: #f4f7fb;
  --sg-vermillion: #ff5543;
  --sg-violet: #a112ff;
  --sg-mint: #8fedcf;
  --sg-black: #000000;
  --sg-btn-hover: #3c26a5;
  --sg-dark-gray: #6C757D;
  --sg-bg-dark-1: #181b26;
  --sg-bg-dark-2: #1d212f;
  --sg-bg-dark-3: #343a4d;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn {
  border: none;
  border-radius: 3px;
  background-color: #5033e1;
  color: #ffffff;
  width: fit-content;
  min-width: fit-content;
  height: 40px;
  font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.0rem;
  margin-top: 10px;
  padding-right: 22px;
  padding-left: 22px;
  justify-self: flex-end;
  transition: all .2s ease-in;
  cursor: pointer;
}

.btn:hover {
  background-color: var(--sg-btn-hover);
  color: white;
}

.btn:active {
  background-color: #ffffff;
  color: #5033e1;
}

button.btn:disabled,
button.btn[disabled] {
  border-color: var(--sg-light-gray);
  background-color: var(--sg-light-gray);
  color: lightgray;
  font-weight: regular;
  cursor: default;
}

.btn.dark {
  border: none;
  border-radius: 3px;
  background-color: var(--sg-blurple);
  color: #ffffff;
  width: fit-content;
  min-width: fit-content;
  height: 40px;
  font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.0rem;
  margin-top: 10px;
  padding-right: 22px;
  padding-left: 22px;
  justify-self: flex-end;
  transition: all .2s ease-in;
  cursor: pointer;
}

.btn.dark:hover {
  background-color: var(--sg-btn-hover);
  color: white;
}

.btn.dark:active {
  background-color: #2b1b79;
  color: white;
}

button.btn.dark:disabled,
button.btn.dark[disabled] {
  border-color: var(--sg-bg-dark-2);
  background-color: var(--sg-bg-dark-2);
  color: var(--sg-bg-dark-3);
  font-weight: regular;
  cursor: default;
}
.desc-list {
    list-style: none;
    text-align: left;
    font-family: "Source Code Pro", monospace;
    font-size: .7rem;
}

.desc-item {
    display: flex;
    flex-flow: column nowrap;
}

.desc-item.dark {
    color: white;
}

.desc-list.placeholder {
    font-size: .8rem;
}

.desc-list.placeholder.dark {
    color: white;
}

.description {
    list-style: none;
    padding-right: 5px;
}

.description.dark {
    background-color: var(--sg-bg-dark-3);
}

.instruction {
    font-weight: normal;
    font-style: italic;
    font-size: .8rem;
}

.row {
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    font-size: .8rem;
    cursor: pointer;
    border-radius: 2px;
    padding-left: 5px;
    padding-right: 5px;
    max-width: 340px;
    /* border: 1px solid black; */
}

.row:hover {
    background-color: var(--sg-light-gray);
}

.row.dark:hover {
    background-color: var(--sg-bg-dark-3);
}

.row.expanded {
    background-color: var(--sg-light-gray);
}

.row.expanded.dark {
    background-color: var(--sg-bg-dark-3);
}

.expand {
    border: none;
    background-color: transparent;
    font-family: "Source Code Pro", monospace;
    font-size: .7rem;
    cursor: pointer;
    transition: .2s;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
    left: 40px;
    opacity: 0;
    transition: all .3s ease-in-out;
}

.expand.dark {
    color: white;
}

.expand.active {
    opacity: 1;
    transform: translateX(-40px);
}

.info-key {
    font-weight: bold;
}

.rotated {
    transform: rotate(-90deg);
    transition-duration: .2s;
}

.not-rotated {
    transition-duration: .2s;
}

.command-info {
    background-color: var(--sg-light-gray);
    font-family: "Source Code Pro", Arial, Helvetica, sans-serif;
    font-size: .7rem;
    padding-bottom: 10px;
    width: 100%;
    max-width: 350px;
    animation: fadeIN .2s linear;
    opacity: 1;
}

.command-info.dark {
    background-color: var(--sg-bg-dark-3);
    color: white;
}

@keyframes fadeIN {

    0% {
        opacity: 0;
        -webkit-transform: translateY(-15px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

.App {
  text-align: center;
  background-color: #ffffff;
  height: 100vh;
}

.App.dark {
  background-color: var(--sg-bg-dark-1);
}

.container {
  width: 65%;
  max-width: 1020px;
  margin: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.deploy-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 45%;
  min-width: 320px;
  min-height: 450px;
  max-height: 500px;
  margin-left: auto;
  margin-right: 0px;
}

.line-break {
  height: 400px;
  min-height: 400px;
  border-left: 1px solid lightgray;
  margin-left: 30px;
  margin-right: 30px;
}

.descriptions-container {
  width: 50%;
  min-width: 320px;
  min-height: 450px;
  max-height: 500px;
}

.logo {
  width: 300px;
  margin-top: 100px;
}

.subtitle {
  font-family: "Source Sans Pro", monospace;
  font-style: italic;
  font-weight: 400;
  font-size: 1.0rem;
  color: var(--sg-dark-gray);
}

.subtitle.dark {
  color: white;
}

.section {
  font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-align: left;
}

.section.right {
  text-align: right;
}

.section.dark {
  color: white;
}
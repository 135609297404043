.actions-container {
    padding-top: 10px;
    width: 100%;
    max-width: 325px;
    min-width: 150px;
    text-align: left;
}

.action-option {
    margin-top: 10px;
    width: 100%;
}
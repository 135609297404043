button.copy-button:disabled,
button.copy-button[disabled] {
    border: 2px solid #F4F7Fb;
    border-radius: 3px;
    background-color: #ffffff;
    color: lightgray;
    width: fit-content;
    height: 40px;
    font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
    font-weight: regular;
    font-size: 1.0rem;
    padding-right: 22px;
    padding-left: 22px;
}

.copy-button {
    border: 2px solid #5033e1;
    border-radius: 3px;
    background-color: #ffffff;
    color: #5033e1;
    width: fit-content;
    height: 40px;
    font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 1.0rem;
    padding-right: 22px;
    padding-left: 22px;
}

.copy-button:hover {
    background-color: #5033e1;
    color: white;
    cursor: pointer;
    padding-right: 22px;
    padding-left: 22px;
}
.generated-command-container {
    padding-top: 45px;
    height: fit-content;
    width: 100%;
    min-height: 100px;
    max-width: 800px;
    min-width: 550px;
    margin: auto;
}

.generated-command {
    font-family: "Source Code Pro", monospace;
    font-weight: normal;
    font-size: .9rem;
    background-color: #000000;
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 25px;
    color: var(--sg-mint);
    border-radius: 3px;
}

.generated-command.dark {
    background-color: var(--sg-bg-dark-3);
    color: #ffffff;
}

.copied-message.show {
    color: #ED2E20;
    font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
    font-size: 1.0;
    font-weight: light;
    animation-name: fadeIn;
    animation-duration: .4s;
    text-align: left;
    transition-duration: .4s;
}

.copied-message.dark {
    color: var(--sg-mint);
}

.btns-container {
    width: 100%;
    max-width: 800px;
    min-width: 550px;
    margin: auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.btns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 36%;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}